import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import transitions from '../modules/transitions';
import flash from '../modules/flash';
import calculations from '../modules/calculations';
import ui from '../modules/ui';

export default history =>
  combineReducers({
    router: connectRouter(history),
    transitions,
    flash,
    calculations,
    ui
  });

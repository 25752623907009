import React from 'react';
import { Spinner } from '@blueprintjs/core';

import styles from './Transitions.scss';

export const Content = props => {
  return (
    <div
      className={
        props.isLoading
          ? styles['transitions__content--active']
          : styles['transitions__content--deactive']
      }
    >
      <div className={styles.transitions__content__loader}>
        <Spinner size={70} />
      </div>
    </div>
  );
};

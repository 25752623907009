import React from 'react';

import { Link } from 'react-router-dom';

import { gTagEvent } from '../../modules/analytics';

import styles from './Buttons.scss';

const tagEvent = event => {
  gTagEvent(event);
};

export const SubmitButton = props => {
  return (
    <button
      className={props.className}
      type="submit"
      onClick={() =>
        tagEvent({
          event: 'SubmitButtonClick',
          event_category: props.text,
          event_label: 'submit'
        })
      }
    >
      {props.text}
    </button>
  );
};

export const ActionButton = ({ to, children, ...props }) => {
  return (
    <>
      {to.toLowerCase().indexOf('mailto:') > -1 ? (
        <a href={to} className={styles.alink} {...props}>
          {children}
        </a>
      ) : (
        <Link to={to} className={styles.link} {...props}>
          <button
            className={styles.link__button}
            onClick={() =>
              tagEvent({
                event: 'ActionButtonClick',
                event_category: to,
                event_label: 'redirection'
              })
            }
          >
            {children}
          </button>
        </Link>
      )}
    </>
  );
};

export const RENDEMENT_CALCULATE =
  process.env.APP + '/calculations:rendement/CALCULATE';

const initialState = {
  rendement: {
    oppervlakte: '',
    elekPrijs: '',
    stroomverbruik: '',
    orientatie: {},
    name: '',
    phone: '',
    email: '',
    helling: '',
    typeDak: {},
    schaduw: {}
  }
};

export default (state = initialState, { type, value }) => {
  switch (type) {
    case RENDEMENT_CALCULATE:
      return { ...state, rendement: { ...state.rendement, ...value } };
    default:
      return { ...state };
  }
};

export const calculateRendement = calculation => dispatch =>
  dispatch({
    type: RENDEMENT_CALCULATE,
    value: {
      ...calculation
    }
  });

import React from 'react';

export const Processing = props => {
  return <h1>Authenticating...</h1>;
};

export const Validated = props => {
  return <h1>Validated !</h1>;
};

export const Failed = props => {
  return <h1>Failed!</h1>;
};

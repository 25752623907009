import React from 'react';
import { Alert, Intent } from '@blueprintjs/core';

export const FlashMessages = props => {
  let messagesList = [];
  if (props.flash && props.flash.messages) {
    return props.flash.messages.map((message, index) => {
      switch (message.type) {
        case 'error':
          return (
            <Alert
              key={index}
              {...message}
              className={message.type}
              cancelButtonText={message.cancelButtonText || 'Cancel'}
              confirmButtonText={message.confirmButtonText || 'Reload'}
              icon={message.icon || 'error'}
              intent={Intent.DANGER}
              // FIY planning to seperate flash.messages in flash.errors || flash.infos || flash.warnings
              // this way I can also schedule alerts, first errors, 2nd warnings and 3rd infos
              isOpen={props.flash.messages.length > 0}
              onCancel={props.clearMessages}
              onConfirm={message.errorHandler ? message.errorHandler : null}
            >
              <p>{message.message}</p>
            </Alert>
          );
        case 'warning':
          return (
            <Alert
              key={index}
              {...message}
              className={message.type}
              cancelButtonText="Ok"
              icon={message.icon || 'warning-sign'}
              intent={Intent.WARNING}
              isOpen={props.flash.messages.length > 0}
              onCancel={props.clearMessages}
            >
              <p>{message.message}</p>
            </Alert>
          );
        case 'info':
        default:
          return (
            <Alert
              key={index}
              {...message}
              className={message.type}
              icon={message.icon || 'info-sign'}
              intent={message.intent || Intent.PRIMARY}
              isOpen={props.flash.messages.length > 0}
              confirmButtonText={message.confirmButtonText || 'Sluit'}
              onConfirm={props.clearMessages}
            >
              <p>{message.message}</p>
            </Alert>
          );
      }
    });
  } else {
    messagesList = [];
  }
  return <div>{messagesList}</div>;
};

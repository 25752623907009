import React from 'react';

export default ({ icon }) => {
  switch (icon) {
    case 'arrow-right':
      return (
        <svg viewBox="0 0 20 20">
          <path d="M13.71 9.29l-6-6a1.003 1.003 0 0 0-1.42 1.42l5.3 5.29-5.29 5.29c-.19.18-.3.43-.3.71a1.003 1.003 0 0 0 1.71.71l6-6c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z" />
        </svg>
      );
    case 'layers':
      return (
        <svg viewBox="0 0 51 51" style={{ enableBackground: 'new 0 0 51 51' }}>
          <polygon
            style={{ fill: '#EFCE4A' }}
            points="50.956,14.456 25.5,29 0.044,14.456 25.5,0"
          />
          <polygon
            style={{ fill: '#ED8A19' }}
            points="25.5,29 9.7,19.973 0.044,25.456 25.5,40 50.956,25.456 41.3,19.973"
          />
          <polygon
            style={{ fill: '#EA6248' }}
            points="25.5,40 9.7,30.973 0.044,36.456 25.5,51 50.956,36.456 41.3,30.973"
          />
        </svg>
      );
    case 'diamont':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <polygon style={{ fill: '#CC2E48' }} points="29,55 0,19 58,19 " />
          <polygon style={{ fill: '#FC3952' }} points="58,19 0,19 10,3 48,3 " />
          <polygon
            style={{ fill: '#F76363' }}
            points="42.154,19 48,3 10,3 15.846,19 "
          />
          <polygon style={{ fill: '#F49A9A' }} points="42,19 29,3 16,19 " />
          <polygon
            style={{ fill: '#CB465F' }}
            points="15.846,19 29,55 42.154,19 "
          />
        </svg>
      );
    case 'state':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <circle
            style={{
              fill: '#C7CAC7',
              stroke: '#949493',
              strokeWidth: 2,
              strokeMiterlimit: 10
            }}
            cx="25.749"
            cy="34.249"
            r="16"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#949493',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="37.062"
            y1="22.935"
            x2="25.749"
            y2="34.249"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#EFCE4A',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="25.749"
            y1="1.249"
            x2="25.749"
            y2="7.249"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#D75A4A',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="50.497"
            y1="11.5"
            x2="46.255"
            y2="15.743"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#659C35',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="5.243"
            y1="15.743"
            x2="1"
            y2="11.5"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#ED8A19',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="34.807"
            y1="2.441"
            x2="33.254"
            y2="8.237"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#A4E869',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="16.69"
            y1="2.441"
            x2="18.243"
            y2="8.237"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#A4E869',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="18.243"
            y1="8.237"
            x2="16.69"
            y2="2.441"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#DC691D',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="43.249"
            y1="5.938"
            x2="40.249"
            y2="11.134"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#88C057',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="11.249"
            y1="11.134"
            x2="8.249"
            y2="5.938"
          />
        </svg>
      );
    case 'guide':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <line
            style={{
              fill: 'none',
              stroke: '#949493',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="28.5"
            y1="50"
            x2="28.5"
            y2="27"
          />
          <rect
            x="20.5"
            y="54"
            style={{ fill: '#949493' }}
            width="16"
            height="4"
          />
          <rect
            x="23.5"
            y="50"
            style={{ fill: '#C7CAC7' }}
            width="10"
            height="4"
          />
          <path
            style={{ fill: '#23A24D' }}
            d="M13.5,27h24.859c1.183,0,2.141-0.959,2.141-2.141v-5.718c0-1.183-0.959-2.141-2.141-2.141H13.5l-5,5 L13.5,27z"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#949493',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="28.5"
            y1="11"
            x2="28.5"
            y2="8"
          />
          <path
            style={{ fill: '#43B05C' }}
            d="M44.5,11H19.641c-1.183,0-2.141,0.959-2.141,2.141V17h20.859c1.183,0,2.141,0.959,2.141,2.141V21h4 l5-5L44.5,11z"
          />
          <circle style={{ fill: '#C7CAC7' }} cx="28.5" cy="4" r="4" />
        </svg>
      );
    case 'tiles':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <path
            style={{ fill: '#EFCE4A' }}
            d="M21.66,24H2.34C1.048,24,0,22.952,0,21.66V2.34C0,1.048,1.048,0,2.34,0h19.32 C22.952,0,24,1.048,24,2.34v19.32C24,22.952,22.952,24,21.66,24z"
          />
          <path
            style={{ fill: '#7FABDA' }}
            d="M51.66,24H32.34C31.048,24,30,22.952,30,21.66V2.34C30,1.048,31.048,0,32.34,0h19.32 C52.952,0,54,1.048,54,2.34v19.32C54,22.952,52.952,24,51.66,24z"
          />
          <path
            style={{ fill: '#D75A4A' }}
            d="M21.66,54H2.34C1.048,54,0,52.952,0,51.66V32.34C0,31.048,1.048,30,2.34,30h19.32 c1.292,0,2.34,1.048,2.34,2.34v19.32C24,52.952,22.952,54,21.66,54z"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#23A24D',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="42"
            y1="30"
            x2="42"
            y2="54"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#23A24D',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="30"
            y1="42"
            x2="54"
            y2="42"
          />
        </svg>
      );

    case 'synergy':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <g>
            <path
              style={{ fill: '#424A60' }}
              d="M42.111,8.847c-0.473-0.29-1.087-0.143-1.376,0.327c-0.29,0.471-0.143,1.086,0.327,1.376
              C48.224,14.96,52.5,22.604,52.5,31c0,2.067-0.271,4.137-0.805,6.152c-0.141,0.533,0.177,1.081,0.711,1.223
              c0.085,0.022,0.172,0.033,0.256,0.033c0.443,0,0.848-0.296,0.966-0.744C54.207,35.482,54.5,33.24,54.5,31
              C54.5,21.905,49.869,13.623,42.111,8.847z"
            />
            <path
              style={{ fill: '#424A60' }}
              d="M37.671,53.182C34.756,54.388,31.671,55,28.5,55c-6.728,0-13.189-2.854-17.728-7.832
              c-0.372-0.408-1.003-0.437-1.413-0.065c-0.408,0.372-0.437,1.005-0.065,1.413C14.211,53.907,21.211,57,28.5,57
              c3.435,0,6.778-0.663,9.936-1.971c0.51-0.211,0.753-0.796,0.542-1.307C38.767,53.213,38.181,52.971,37.671,53.182z"
            />
            <path
              style={{ fill: '#424A60' }}
              d="M22.784,7.687c0.537-0.132,0.865-0.673,0.734-1.209c-0.13-0.536-0.674-0.862-1.208-0.734
              C11.302,8.434,3.371,17.838,2.573,29.146c-0.039,0.551,0.376,1.029,0.927,1.067c0.024,0.002,0.048,0.003,0.071,0.003
              c0.521,0,0.959-0.402,0.997-0.93C5.305,18.85,12.625,10.169,22.784,7.687z"
            />
          </g>
          <circle style={{ fill: '#EBBA16' }} cx="28.5" cy="6" r="6" />
          <circle style={{ fill: '#7383BF' }} cx="6.5" cy="43" r="6" />
          <circle style={{ fill: '#43B05C' }} cx="50.5" cy="43" r="6" />
        </svg>
      );

    case 'panels':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <rect
            style={{ fill: '#556080' }}
            x="7"
            y="47"
            width="10"
            height="10"
          />
          <rect
            style={{ fill: '#556080' }}
            x="27"
            y="47"
            width="10"
            height="10"
          />
          <rect
            style={{ fill: '#556080' }}
            x="47"
            y="47"
            width="10"
            height="10"
          />
          <rect
            style={{ fill: '#556080' }}
            x="7"
            y="27"
            width="10"
            height="10"
          />
          <rect
            style={{ fill: '#556080' }}
            x="27"
            y="27"
            width="10"
            height="10"
          />
          <rect
            style={{ fill: '#556080' }}
            x="47"
            y="27"
            width="10"
            height="10"
          />
          <rect
            style={{ fill: '#556080' }}
            x="7"
            y="7"
            width="10"
            height="10"
          />
          <rect
            style={{ fill: '#556080' }}
            x="27"
            y="7"
            width="10"
            height="10"
          />
          <rect
            style={{ fill: '#556080' }}
            x="47"
            y="7"
            width="10"
            height="10"
          />
        </svg>
      );
    case 'researchDocs':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <polygon
            style={{ fill: '#EDEADA' }}
            points="49,20 34,5 11,5 11,55 49,55 "
          />
          <circle
            style={{
              fill: '#FFFFFF',
              stroke: '#7383BF',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            cx="31.453"
            cy="37.453"
            r="10.828"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#7383BF',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="39.244"
            y1="45.244"
            x2="52"
            y2="58"
          />
          <path
            style={{ fill: '#CEC9AE' }}
            d="M31.625,35h-6c-0.553,0-1-0.447-1-1s0.447-1,1-1h6c0.553,0,1,0.447,1,1S32.178,35,31.625,35z"
          />
          <path
            style={{ fill: '#CEC9AE' }}
            d="M37.625,41h-12c-0.553,0-1-0.447-1-1s0.447-1,1-1h12c0.553,0,1,0.447,1,1S38.178,41,37.625,41z"
          />
          <polygon
            style={{ fill: '#E2DBBF' }}
            points="11,5 34,5 44,15 44,0 6,0 6,50 11,50 "
          />
          <path
            style={{ fill: '#CEC9AE' }}
            d="M48.712,20H34V5.288c0-0.106,0.129-0.16,0.204-0.084l14.592,14.592
            C48.871,19.871,48.818,20,48.712,20z"
          />
        </svg>
      );
    case 'development':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <path
            style={{ fill: '#226BAC' }}
            d="M6,0C2.686,0,0,2.686,0,6v45c0-3.314,2.686-6,6-6s6,2.686,6,6V6C12,2.686,9.314,0,6,0z"
          />
          <path
            style={{ fill: '#3083C9' }}
            d="M12,7v44c0-3.314-2.686-6-6-6s-6,2.686-6,6v0.06C0,54.34,2.66,57,5.94,57H57V7H12z"
          />
          <path
            style={{ fill: '#7FABDA' }}
            d="M52,41h-3c-0.553,0-1-0.447-1-1s0.447-1,1-1h1v-0.617c0-0.553,0.447-1,1-1s1,0.447,1,1V41z M45,41h-2
            c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S45.553,41,45,41z M39,41h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2
            c0.553,0,1,0.447,1,1S39.553,41,39,41z M33,41h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S33.553,41,33,41z M27,41
            h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S27.553,41,27,41z M21,41h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2
            c0.553,0,1,0.447,1,1S21.553,41,21,41z M17,39c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2
            C18,38.553,17.553,39,17,39z M51,35.383c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2
            C52,34.936,51.553,35.383,51,35.383z M17,33c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2C18,32.553,17.553,33,17,33z
            M51,29.383c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2C52,28.936,51.553,29.383,51,29.383z M17,27
            c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2C18,26.553,17.553,27,17,27z M50.495,23.644
            c-0.199,0-0.4-0.06-0.575-0.183l-1.635-1.153c-0.451-0.318-0.56-0.942-0.241-1.394c0.319-0.451,0.942-0.559,1.394-0.241l1.635,1.153
            c0.451,0.318,0.56,0.942,0.241,1.394C51.118,23.496,50.81,23.644,50.495,23.644z M19.452,22.27c-0.314,0-0.623-0.147-0.818-0.423
            c-0.318-0.451-0.211-1.075,0.24-1.395l1.634-1.153c0.453-0.317,1.076-0.21,1.395,0.24c0.318,0.451,0.211,1.075-0.24,1.395
            l-1.634,1.153C19.853,22.21,19.651,22.27,19.452,22.27z M45.593,20.184c-0.199,0-0.4-0.06-0.576-0.183l-1.634-1.153
            c-0.451-0.319-0.559-0.943-0.24-1.395c0.319-0.45,0.941-0.558,1.395-0.24l1.634,1.153c0.451,0.319,0.559,0.943,0.24,1.395
            C46.216,20.036,45.907,20.184,45.593,20.184z M24.354,18.81c-0.314,0-0.623-0.147-0.818-0.423c-0.318-0.451-0.211-1.075,0.24-1.395
            l1.634-1.153c0.453-0.316,1.075-0.209,1.395,0.24c0.318,0.451,0.211,1.075-0.24,1.395l-1.634,1.153
            C24.754,18.75,24.553,18.81,24.354,18.81z M40.691,16.724c-0.199,0-0.4-0.06-0.576-0.183l-1.634-1.153
            c-0.451-0.319-0.559-0.943-0.24-1.395c0.319-0.45,0.941-0.558,1.395-0.24l1.634,1.153c0.451,0.319,0.559,0.943,0.24,1.395
            C41.314,16.576,41.006,16.724,40.691,16.724z M29.256,15.35c-0.314,0-0.623-0.147-0.818-0.423c-0.318-0.451-0.211-1.075,0.24-1.395
            l1.634-1.153c0.454-0.318,1.075-0.209,1.395,0.24c0.318,0.451,0.211,1.075-0.24,1.395l-1.634,1.153
            C29.656,15.29,29.455,15.35,29.256,15.35z M35.789,13.264c-0.199,0-0.4-0.06-0.576-0.183l-1.634-1.153
            c-0.451-0.319-0.559-0.943-0.24-1.395c0.319-0.449,0.941-0.557,1.395-0.24l1.634,1.153c0.451,0.319,0.559,0.943,0.24,1.395
            C36.412,13.116,36.104,13.264,35.789,13.264z"
          />
          <rect
            x="41"
            y="46"
            style={{
              fill: 'none',
              stroke: '#EFCE4A',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            width="11"
            height="6"
          />
          <path
            style={{ fill: '#7FABDA' }}
            d="M30,36h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h1v-1c0-0.553,0.447-1,1-1s1,0.447,1,1v2
            C31,35.553,30.553,36,30,36z M24,36h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S24.553,36,24,36z M22,32
            c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2C23,31.553,22.553,32,22,32z M30,30c-0.553,0-1-0.447-1-1v-2
            c0-0.553,0.447-1,1-1s1,0.447,1,1v2C31,29.553,30.553,30,30,30z M26,28h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1
            S26.553,28,26,28z"
          />
          <path
            style={{ fill: '#7FABDA' }}
            d="M46,36h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h1v-1c0-0.553,0.447-1,1-1s1,0.447,1,1v2
            C47,35.553,46.553,36,46,36z M40,36h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S40.553,36,40,36z M38,32
            c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2C39,31.553,38.553,32,38,32z M46,30c-0.553,0-1-0.447-1-1v-2
            c0-0.553,0.447-1,1-1s1,0.447,1,1v2C47,29.553,46.553,30,46,30z M42,28h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1
            S42.553,28,42,28z"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#C6E2F7',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="37"
            y1="46"
            x2="32"
            y2="46"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#C6E2F7',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="37"
            y1="49"
            x2="30"
            y2="49"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#C6E2F7',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            x1="37"
            y1="52"
            x2="27"
            y2="52"
          />
        </svg>
      );
    case 'approvedDoc':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <polygon
            style={{ fill: '#EDEADA' }}
            points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 "
          />
          <polygon
            style={{ fill: '#CEC9AE' }}
            points="37.5,0 37.5,14 51.5,14 "
          />
          <g>
            <path
              style={{ fill: '#CEC9AE' }}
              d="M15.5,16h9c0.553,0,1-0.447,1-1s-0.447-1-1-1h-9c-0.553,0-1,0.447-1,1S14.947,16,15.5,16z"
            />
            <path
              style={{ fill: '#CEC9AE' }}
              d="M15.5,24h5c0.553,0,1-0.447,1-1s-0.447-1-1-1h-5c-0.553,0-1,0.447-1,1S14.947,24,15.5,24z"
            />
            <path
              style={{ fill: '#CEC9AE' }}
              d="M37.5,22h-7c-0.553,0-1,0.447-1,1s0.447,1,1,1h7c0.553,0,1-0.447,1-1S38.053,22,37.5,22z"
            />
            <path
              style={{ fill: '#CEC9AE' }}
              d="M24.79,22.3c-0.19,0.18-0.29,0.439-0.29,0.7c0,0.26,0.1,0.52,0.29,0.71
              C24.979,23.89,25.24,24,25.5,24s0.52-0.101,0.71-0.3c0.18-0.181,0.29-0.44,0.29-0.7c0-0.261-0.11-0.521-0.29-0.71
              C25.83,21.92,25.16,21.92,24.79,22.3z"
            />
            <path
              style={{ fill: '#CEC9AE' }}
              d="M43.21,22.29c-0.38-0.37-1.04-0.37-1.42,0c-0.181,0.189-0.29,0.449-0.29,0.71
              c0,0.26,0.109,0.52,0.29,0.71C41.979,23.89,42.24,24,42.5,24s0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71
              C43.5,22.739,43.39,22.479,43.21,22.29z"
            />
            <path
              style={{ fill: '#CEC9AE' }}
              d="M27.5,30h-7c-0.553,0-1,0.447-1,1s0.447,1,1,1h7c0.553,0,1-0.447,1-1S28.053,30,27.5,30z"
            />
            <path
              style={{ fill: '#CEC9AE' }}
              d="M14.79,30.29C14.6,30.479,14.5,30.729,14.5,31c0,0.26,0.109,0.52,0.29,0.71
              C14.979,31.89,15.24,32,15.5,32s0.52-0.11,0.71-0.29c0.189-0.19,0.29-0.45,0.29-0.71c0-0.261-0.101-0.521-0.29-0.71
              C15.83,29.92,15.17,29.92,14.79,30.29z"
            />
          </g>
          <g>
            <path
              style={{ fill: '#7383BF' }}
              d="M18.645,39.166c-2.191,1.141-2.928,3.321-3.196,5.582c-0.414-0.347-0.828-0.693-1.241-1.04
              c-0.981-0.822-2.404,0.585-1.414,1.415c0.935,0.783,1.871,1.567,2.806,2.351c0.662,0.555,1.676,0.211,1.707-0.707
              c0.073-2.167,0.177-4.743,2.348-5.873C20.799,40.298,19.787,38.572,18.645,39.166z"
            />
            <path
              style={{ fill: '#7383BF' }}
              d="M27.983,44.25c-0.52-0.93-1.642-1.391-2.688-1.095c-1.365,0.386-2.981,2.042-3.936,0.106
              c-0.571-1.157-2.295-0.143-1.727,1.009c0.514,1.042,1.5,1.798,2.669,1.932c0.607,0.069,1.117-0.013,1.688-0.226
              c0.406-0.152,1.923-1.332,2.267-0.717C26.885,46.385,28.613,45.376,27.983,44.25z"
            />
          </g>
          <path
            style={{ fill: '#EBBA16' }}
            d="M40.045,29.09L40.045,29.09c0.326,0.915,1.371,1.349,2.249,0.931l0,0
            c1.393-0.662,2.847,0.791,2.185,2.185l0,0c-0.417,0.877,0.016,1.923,0.931,2.249h0c1.454,0.517,1.454,2.573,0,3.09h0
            c-0.915,0.326-1.349,1.371-0.931,2.249l0,0c0.662,1.393-0.791,2.847-2.185,2.185l0,0c-0.877-0.417-1.923,0.016-2.249,0.931v0
            c-0.517,1.454-2.573,1.454-3.09,0v0c-0.326-0.915-1.371-1.349-2.249-0.931l0,0c-1.393,0.662-2.847-0.791-2.185-2.185l0,0
            c0.417-0.877-0.016-1.923-0.931-2.249h0c-1.454-0.517-1.454-2.573,0-3.09h0c0.915-0.326,1.349-1.371,0.931-2.249l0,0
            c-0.662-1.393,0.791-2.847,2.185-2.185l0,0c0.877,0.417,1.923-0.016,2.249-0.931v0C37.472,27.637,39.528,27.637,40.045,29.09z"
          />
          <path
            style={{ fill: '#D75A4A' }}
            d="M42.294,41.979c-0.878-0.417-1.923,0.016-2.249,0.931c-0.517,1.454-2.573,1.454-3.09,0
            c-0.326-0.916-1.371-1.349-2.249-0.931c-0.069,0.033-0.137,0.038-0.206,0.061V53l4-4l4,4V42.039
            C42.431,42.017,42.363,42.011,42.294,41.979z"
          />
        </svg>
      );
    case 'guidance':
      return (
        <svg viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 51 51' }}>
          <polygon style={{ fill: '#556080' }} points="18,44 0,50 0,7 18,1 " />
          <polygon
            style={{ fill: '#465066' }}
            points="52,42 35,51 35,10 52,1 "
          />
          <line
            style={{
              fill: 'none',
              stroke: '#424A60',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            x1="10"
            y1="17"
            x2="10"
            y2="20"
          />
          <circle style={{ fill: '#D75A4A' }} cx="10" cy="13" r="4" />
          <path
            style={{ fill: '#7383BF' }}
            d="M31.418,33.497c-0.542-0.104-0.896-0.63-0.792-1.172s0.628-0.898,1.172-0.791
	C32.917,31.75,33.963,31.837,35,31.8V10L18,1v28.228c0.625-0.076,1.263-0.127,1.918-0.141c0.007,0,0.015,0,0.022,0
	c0.542,0,0.988,0.434,1,0.979c0.012,0.553-0.426,1.01-0.979,1.021c-0.674,0.014-1.328,0.068-1.961,0.155V44l17,7V33.799
	c-0.14,0.004-0.282,0.016-0.421,0.016C33.571,33.815,32.508,33.708,31.418,33.497z M28.708,31.74
	c-0.161,0.384-0.532,0.614-0.923,0.614c-0.128,0-0.259-0.024-0.385-0.077c-0.357-0.149-0.614-0.27-0.748-0.334
	c-0.984-0.25-1.951-0.448-2.877-0.59c-0.546-0.083-0.921-0.594-0.838-1.14c0.083-0.546,0.594-0.923,1.14-0.837
	c1.023,0.156,2.09,0.376,3.173,0.654c0.071,0.019,0.14,0.045,0.205,0.078c0.017,0.009,0.275,0.138,0.716,0.322
	C28.68,30.645,28.92,31.23,28.708,31.74z"
          />
          <path
            style={{ fill: '#EFCE4A' }}
            d="M15.736,29.653c-0.534,0.142-0.852,0.689-0.71,1.223c0.119,0.448,0.523,0.744,0.966,0.744
	c0.084,0,0.171-0.011,0.257-0.033c0.56-0.148,1.146-0.262,1.751-0.345v-2.014C17.217,29.323,16.459,29.462,15.736,29.653z"
          />
          <path
            style={{ fill: '#EFCE4A' }}
            d="M11.805,31.342c-1.207,0.757-2.295,1.705-3.235,2.82c-0.356,0.422-0.302,1.053,0.12,1.409
	c0.188,0.158,0.417,0.235,0.644,0.235c0.285,0,0.567-0.121,0.765-0.355c0.806-0.956,1.737-1.769,2.768-2.414
	c0.468-0.294,0.609-0.911,0.316-1.379C12.89,31.19,12.272,31.048,11.805,31.342z"
          />
          <path
            style={{ fill: '#EFCE4A' }}
            d="M7.627,37.276c-0.502-0.23-1.096-0.013-1.327,0.489c-1.026,2.226-1.28,4.023-1.291,4.099
	c-0.075,0.547,0.307,1.05,0.854,1.125c0.046,0.007,0.093,0.01,0.138,0.01c0.491,0,0.919-0.362,0.99-0.861
	c0.002-0.016,0.231-1.597,1.125-3.534C8.347,38.102,8.128,37.508,7.627,37.276z"
          />
          <path
            style={{ fill: '#AF903A' }}
            d="M47.068,13.919c-0.01-0.553-0.49-0.973-1.018-0.982c-0.552,0.01-0.992,0.466-0.982,1.018
	c0.006,0.347,0.01,0.688,0.01,1.022c0,1-0.03,1.973-0.089,2.893c-0.035,0.551,0.383,1.026,0.934,1.062
	c0.022,0.001,0.043,0.002,0.065,0.002c0.523,0,0.963-0.406,0.997-0.937c0.062-0.961,0.093-1.978,0.093-3.02
	C47.078,14.63,47.075,14.277,47.068,13.919z"
          />
          <path
            style={{ fill: '#AF903A' }}
            d="M45.69,20.914c-0.546-0.09-1.065,0.257-1.167,0.8c-0.252,1.348-0.595,2.58-1.019,3.663
	c-0.201,0.514,0.052,1.094,0.566,1.296c0.12,0.047,0.243,0.068,0.364,0.068c0.4,0,0.777-0.241,0.932-0.636
	c0.47-1.199,0.847-2.553,1.123-4.024C46.59,21.539,46.233,21.017,45.69,20.914z"
          />
          <path
            style={{ fill: '#AF903A' }}
            d="M41.702,28.582c-0.776,0.967-1.696,1.726-2.735,2.257c-0.492,0.252-0.687,0.854-0.435,1.346
	c0.177,0.346,0.527,0.545,0.891,0.545c0.153,0,0.309-0.035,0.454-0.109c1.292-0.661,2.431-1.599,3.384-2.786
	c0.346-0.431,0.277-1.061-0.154-1.406C42.678,28.085,42.048,28.151,41.702,28.582z"
          />
          <path
            style={{ fill: '#AF903A' }}
            d="M35.512,31.779c-0.17,0.013-0.341,0.015-0.512,0.021v1.999c0.222-0.007,0.443-0.009,0.667-0.026
	c0.55-0.043,0.962-0.523,0.919-1.074C36.544,32.148,36.061,31.729,35.512,31.779z"
          />
          <path
            style={{ fill: '#F9EE2B' }}
            d="M28.17,30.432c-0.441-0.185-0.699-0.313-0.716-0.322c-0.065-0.033-0.134-0.06-0.205-0.078
	c-1.083-0.278-2.15-0.498-3.173-0.654c-0.545-0.086-1.056,0.291-1.14,0.837c-0.083,0.546,0.292,1.057,0.838,1.14
	c0.926,0.142,1.893,0.34,2.877,0.59c0.134,0.064,0.39,0.185,0.748,0.334c0.126,0.053,0.257,0.077,0.385,0.077
	c0.391,0,0.763-0.23,0.923-0.614C28.92,31.23,28.68,30.645,28.17,30.432z"
          />
          <path
            style={{ fill: '#F9EE2B' }}
            d="M31.799,31.534c-0.544-0.107-1.067,0.249-1.172,0.791s0.25,1.067,0.792,1.172
	c1.089,0.211,2.153,0.318,3.16,0.318c0.139,0,0.281-0.012,0.421-0.016V31.8C33.963,31.837,32.917,31.75,31.799,31.534z"
          />
          <path
            style={{ fill: '#F9EE2B' }}
            d="M20.94,30.065c-0.012-0.545-0.457-0.979-1-0.979c-0.007,0-0.015,0-0.022,0
	c-0.655,0.014-1.294,0.065-1.918,0.141v2.014c0.633-0.087,1.288-0.14,1.961-0.155C20.514,31.075,20.952,30.618,20.94,30.065z"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#424A60',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            x1="8"
            y1="23"
            x2="12"
            y2="27"
          />
          <line
            style={{
              fill: 'none',
              stroke: '#424A60',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            x1="8"
            y1="27"
            x2="12"
            y2="23"
          />
        </svg>
      );
    case '--heading-icon':
      return (
        <svg>
          <circle fill="#B9F4BC" cx="33" cy="33" r="33" />
          <path
            d="M15.7 45.3c-.7-2-.7-3.3-.7-8v-8.7c0-4.6 0-6 .7-8 .8-2.2 2.7-4 5-5 2-.6 3.3-.6 8-.6h8.7c4.6 0 6 0 8 .7 2.2.8 4 2.7 5 5 .6 2 .6 3.3.6 8v8.7c0 4.6 0 6-.7 8-.8 2.2-2.7 4-5 5-2 .6-3.3.6-8 .6h-8.7c-4.6 0-6 0-8-.7-2.2-.8-4-2.7-5-5z"
            fill="#6ED69A"
          />
          <g>
            <rect
              fill="rgb(185,244,188)"
              x="23"
              y="27"
              width="20"
              height="2"
              rx="1"
            />
            <circle fill="#1BB978" cx="27.00049188622944" cy="28" r="4" />
          </g>
          <g>
            <rect
              fill="rgb(185,244,188)"
              x="23"
              y="37"
              width="20"
              height="2"
              rx="1"
            />
            <circle fill="#1BB978" cx="27.002492966661677" cy="38" r="4" />
          </g>
        </svg>
      );
    case '--settings-icon':
      return (
        <svg>
          <circle fill="#B9F4BC" cx="33" cy="33" r="33" />
          <path
            d="M38.4 15l1-3h1l1.2 3c.2.2.5.2.7.3l2.2-2.5 1 .4-.2 3.3c.2 0 .3.2.5.4l3-1.5.7.7-1.4 3 .5.5h3.3l.4.8-2.5 2.2c0 .2 0 .5.2.7l3 1v1l-3 1.2-.3.8 2.5 2-.4 1-3.3-.2-.4.7 1.5 2.8-.7.7-3-1.4c0 .2-.4.4-.6.5l.2 3.3-1 .4-2-2.5c-.3 0-.6 0-1 .2l-1 3h-1l-1-3c-.2-.2-.5-.2-.8-.3l-2 2.5-1-.4.2-3.3-.7-.4-2.8 1.5-.7-.7 1.4-3c-.2 0-.4-.4-.5-.6l-3.3.2-.4-1 2.5-2c0-.3 0-.6-.2-1l-3-1v-1l3-1c.2-.2.2-.4.3-.7l-2.5-2.2.4-1 3.3.2c0-.2.2-.3.4-.5l-1.5-3 .7-.7 3 1.4.5-.5v-3.3l.8-.4 2.2 2.5s.5 0 .7-.2z"
            fill="#6ED69A"
            transform="rotate(117.19531200000644 40 25)"
          />
          <circle fill="#B9F4BC" cx="40" cy="25" r="2" />
          <path
            d="M21.6 26.8L19 25l-1.3 1 1.4 3c0 .2-.3.4-.5.6l-3-.8-1 1.4 2.4 2.3-.4.8-3.2.3-.3 1.6 3 1.4v.8l-3 1.4.4 1.6 3.2.3c0 .3.2.5.3.8l-2.4 2.3.8 1.4 3-.8.7.6-1.3 3 1.3 1 2.6-1.8c.3 0 .5.3.8.4l-.3 3.2 1.6.6 2-2.7c.2 0 .5 0 .7.2l1 3h1.5l1-3c0-.2.4-.2.7-.3l2 2.7 1.4-.6-.4-3.2c.3 0 .5-.3.8-.4L37 49l1.3-1-1.4-3c0-.2.3-.4.5-.6l3 .8 1-1.4-2.4-2.3.4-.8 3.2-.3.3-1.6-3-1.4v-.8l3-1.4-.4-1.6-3.2-.3c0-.3-.2-.5-.3-.8l2.4-2.3-.8-1.4-3 .8-.7-.6 1.3-3-1.3-1-2.6 1.8c-.3 0-.5-.3-.8-.4l.3-3.2-1.6-.6-2 2.7c-.2 0-.5 0-.7-.2l-1-3h-1.5l-1 3c0 .2-.4.2-.7.3l-2-2.7-1.4.6.4 3.2c-.3 0-.5.3-.8.4z"
            fill="#1BB978"
            transform="rotate(-117.19531200000644 28 37)"
          />
          <circle fill="#B9F4BC" cx="28" cy="37" r="3" />
        </svg>
      );
    default:
      return <></>;
  }
};

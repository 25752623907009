import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';

import { imagePath } from '../../utils/assetUtils';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FlashMessages } from '../../components/FlashMessages';
import * as Transitions from '../../components/Transitions';
import { clearMessages } from '../../modules/flash';
import { setRender } from '../../modules/ui';

import { Nav } from '../../components/Nav';
const HOST = process.env.HOST || 'localhost';
const PORT = +process.env.HOST_PORT || 80;
const PROTOCOL = process.env.PROTOCOL || 'http';
const SITE_URL =
  `${PROTOCOL}://${HOST}` + (+PORT !== 80 && +PORT !== 443 ? `:${PORT}` : '');

const defaultTitle = 'Starborn';
const defaultDescription =
  'Starborn ontwikkelt, adviseert en investeert in grootschalige zonneparken op daken, velden en water.';
const defaultImage = `${SITE_URL}${imagePath('images/logo.png')}`;
const defaultTwitter = '@starborn';
const defaultSep = ' | ';

class Page extends Component {
  getMetaTags(
    {
      title,
      description,
      keywords,
      image,
      contentType,
      twitter,
      noCrawl,
      published,
      updated,
      category,
      tags
    },
    pathname
  ) {
    const theTitle = title
      ? (title + defaultSep + defaultTitle).substring(0, 60)
      : defaultTitle;
    const theDescription = description
      ? description.substring(0, 155)
      : defaultDescription;
    const theImage = image ? `${SITE_URL}${image}` : defaultImage;
    const theKeywords = keywords
      ? keywords
      : [
          'zon',
          'zon pv',
          'zonpv',
          'zonne-energie',
          'duurzaam',
          'duurzame energie',
          'hernieuwbaar',
          'hernieuwbare energie',
          'sde',
          'sde+',
          'dak',
          'zon-op-dak',
          'zon op daken',
          'projectontwikkeling',
          'project',
          'ontwikkeling',
          'advies',
          'zonnepanelen',
          'groothandel',
          'nederland',
          'hongarije',
          'vietnam',
          'oekraine',
          'turkije',
          'voordeel',
          'voordelig',
          'goedkoop',
          'betrouwbaar',
          'sde tool',
          'sde calculator',
          'rendement',
          'rendementsberekening',
          'wat is uw dak waard?',
          'hoeveel is mijn dak waard?',
          'zonnepanelen op dak',
          'rvo',
          'voorjaar',
          'najaar'
        ].join(', ');

    const metaTags = [
      { itemprop: 'name', content: theTitle },
      { itemprop: 'description', content: theDescription },
      { itemprop: 'keywords', content: theKeywords },
      { itemprop: 'image', content: theImage },
      { name: 'description', content: theDescription },
      { name: 'keywords', content: theKeywords },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: defaultTwitter },
      { name: 'twitter:title', content: theTitle },
      { name: 'twitter:description', content: theDescription },
      { name: 'twitter:keywords', content: theKeywords },
      { name: 'twitter:creator', content: twitter || defaultTwitter },
      { name: 'twitter:image:src', content: theImage },
      { property: 'og:title', content: theTitle },
      { property: 'og:type', content: contentType || 'website' },
      { property: 'og:url', content: SITE_URL + pathname },
      { property: 'og:image', content: theImage },
      { property: 'og:description', content: theDescription },
      { property: 'og:keywords', content: theKeywords },
      { property: 'og:site_name', content: defaultTitle }
    ];

    if (noCrawl) {
      metaTags.push({ name: 'robots', content: 'noindex, nofollow' });
    }

    if (published) {
      metaTags.push({ name: 'article:published_time', content: published });
    }
    if (updated) {
      metaTags.push({ name: 'article:modified_time', content: updated });
    }
    if (category) {
      metaTags.push({ name: 'article:section', content: category });
    }
    if (tags) {
      metaTags.push({ name: 'article:tag', content: tags });
    }
    return metaTags;
  }

  componentDidMount() {
    if (!this.props.ui.render) {
      setTimeout(() => {
        this.props.setRender(false);
      }, 100);
    }
  }

  render() {
    const { children, id, className, ...rest } = this.props;
    const rootClasses =
      this.props.flash.messages.length > 0
        ? [className, id + '--has-error', 'has-error'].join(' ')
        : className;
    const hide = { display: 'none' };
    const show = { display: 'inherit' };
    return (
      <div className={''} style={this.props.ui.render ? show : hide}>
        <FlashMessages {...this.props} />
        <Transitions.Loaders.Content
          {...this.props}
          isLoading={this.props.transitions.isLoading || false}
        />
        <Nav />
        <main id={id} className={rootClasses}>
          <Helmet
            htmlAttributes={{
              lang: 'en',
              itemscope: undefined,
              itemtype: `http://schema.org/${rest.schema || 'WebPage'}`
            }}
            title={
              rest.title ? rest.title + defaultSep + defaultTitle : defaultTitle
            }
            link={[
              {
                rel: 'canonical',
                href: SITE_URL + this.props.location.pathname
              }
            ]}
            meta={this.getMetaTags(rest, this.props.location.pathname)}
            bodyAttributes={{
              transition: this.props.transitions.isLoading || false
            }}
          />
          <FlashMessages {...this.props} />
          <Transitions.Loaders.Content
            {...this.props}
            isLoading={this.props.transitions.isLoading || false}
          />
          {children}
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ transitions, flash, ui }) => ({
  transitions,
  flash,
  ui
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearMessages,
      setRender
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);

export const UI_RENDER = process.env.APP + '/ui/RENDER';

const initialState = {
  render: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_RENDER:
      return {
        ...state,
        render: action.value
      };
    default:
      return state;
  }
};

export const setRender = (isLoading = false) => dispatch =>
  dispatch({
    type: UI_RENDER,
    value: isLoading ? false : true
  });

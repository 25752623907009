import React, { Component } from 'react';

import AppRoutes from '../../routes';

import './App.scss';

import '../../componentStyles/index.scss';
import '../../styles/deps.css';
import '../../styles/index.scss';

export default class App extends Component {
  render() {
    return <AppRoutes />;
  }
}

export const TRANSITION_PENDING = process.env.APP + '/trans/PENDING';
export const TRANSITION_FINISHED = process.env.APP + '/trans/FINISHED';

const initialState = {
  isLoading: false,
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSITION_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSITION_FINISHED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export const setLoadingState = (isLoading = false) => dispatch =>
  dispatch({
    type: isLoading ? TRANSITION_PENDING : TRANSITION_FINISHED
  });

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import NotFound from '../pages/NotFound';

export const HomePage = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ '../pages/HomePage'),
  loading: () => null,
  modules: ['homepage']
});

export const ContentPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "content" */ '../pages/ContentPage'),
  loading: () => null,
  modules: ['content']
});

export const FormPage = Loadable({
  loader: () => import(/* webpackChunkName: "form" */ '../pages/FormPage'),
  loading: () => null,
  modules: ['form']
});

export default () => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/:page\.html" component={ContentPage} />
    <Route exact path="/:page" component={FormPage} />
    <Route component={NotFound} />
  </Switch>
);

export const gTagInit = () => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', 'UA-125468800-1');
  }
};

export const gTagEvent = ({ event, event_category, event_label }) => {
  window.gtag('event', event, {
    event_category,
    event_label
  });
};

export const gTagPageview = props => {
  window.gtag('config', 'UA-125468800-1', {
    ...props
  });
};

export const timeoutTime = 2000;

import React from 'react';
import { Link } from 'react-router-dom';
import Icons from '../Icons';
import stylesIndex from './index.scss';

import { imagePath } from '../../utils/assetUtils';

import { ActionButton } from '../Buttons/Buttons';

import {
  CALCULATOR,
  DIENSTEN,
  HOME,
  OVER_ONS,
  VACATURES,
  CONTACT
} from '../../constants';

export class Nav extends React.Component {
  state = {
    mobileMenuVisible: false
  };

  constructor(props) {
    super(props);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.hideMobileMenu = this.hideMobileMenu.bind(this);
  }

  toggleMobileMenu = e => {
    e.preventDefault();
    this.setState(prevState => {
      return {
        mobileMenuVisible: !prevState.mobileMenuVisible
      };
    });
  };

  hideMobileMenu = () => {
    this.setState({
      mobileMenuVisible: false
    });
  };

  render() {
    return (
      <nav
        className={
          stylesIndex.global__nav +
          ' ' +
          (this.state.mobileMenuVisible
            ? stylesIndex['global__nav--active']
            : '')
        }
      >
        <div
          className={stylesIndex.global__nav__overlay}
          onClick={this.toggleMobileMenu}
        />
        <div className={stylesIndex.global__nav__container}>
          <div className={stylesIndex.global__nav__container__logo}>
            <a
              href="#navMenuToggle"
              className={stylesIndex.global__nav__container__logo__toggleButton}
              onClick={this.toggleMobileMenu}
            >
              <Icons icon={'arrow-right'} />
            </a>
            <Link
              to={HOME}
              className={stylesIndex.global__nav__container__logo__imageLink}
            >
              <img
                src={imagePath('images/logo.png')}
                alt={process.env.APP_NAME}
              />
            </Link>
          </div>
          <div className={stylesIndex.global__nav__container__menu}>
            <ul>
              <li>
                <Link onClick={this.hideMobileMenu} to={OVER_ONS}>
                  Over Ons
                </Link>
              </li>
              <li>
                <Link onClick={this.hideMobileMenu} to={DIENSTEN}>
                  Diensten
                </Link>
              </li>
              <li>
                <Link onClick={this.hideMobileMenu} to={VACATURES}>
                  Vacatures
                </Link>
              </li>
              <li>
                <Link onClick={this.hideMobileMenu} to={CONTACT}>
                  Contact
                </Link>
              </li>
              <li className={stylesIndex.global__nav__container__menu__action}>
                <ActionButton to={CALCULATOR}>SDE Calculator</ActionButton>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
